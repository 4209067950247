const Link = dynamic(() => import('next/link'));
import dynamic from 'next/dynamic';
import styled from 'styled-components';
const Div = styled.div`
  background: url('https://res.cloudinary.com/see-sight-tours/image/upload/v1683894849/Remaining%20images/Attraction/Hornblower/Hornblower_3_cgeywi.jpg');
  @media (max-width: 768px) {
    background: url('https://res.cloudinary.com/see-sight-tours/image/upload/v1685537095/strapi/image1_635baf3db1.jpg');
    max-height: 105vh;
    background-repeat: no-repeat !important;
  }
  background-size: cover;
  max-height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
`;
const Custom404 = () => {
  return (
    <Div className="flex flex-col items-start justify-center h-screen gap-2 relative    ">
      <div className="absolute bg-black w-full h-full top-0   bg-opacity-60 z-10"></div>
      <div className="absolute lg:justify-evenly  h-full w-full top-0 z-20 flex flex-col lg:flex-row  items-center py-[50%] lg:py-[10%] px-2   text-center ">
        <h1 className="text-5xl lg:text-[140px] font-bold text-[#F15C5A] mb-4">
          Oops!
        </h1>
        <div>
          <p className="text-3xl lg:text-5xl  text-white mb-4">
            This page cannot be found.
          </p>
          <p className=" mb-4  text-2xl lg:text-5xl text-white ">
            Sorry , the page your looking for is not available. <br />
            You can go to the following:
          </p>

          <nav className="flex gap-3 justify-center flex-wrap mt-8">
            <Link
              className=" border-[#F15C5A] border rounded-md p-2   text-white hover:text-blue-300 mb-2 text-xl lg:text-3xl lg:px-6 "
              href="/blog"
            >
              Go to Blogs
            </Link>
            <Link
              className="border-[#F15C5A] border rounded-md p-2   text-white hover:text-blue-300 mb-2 text-xl lg:text-3xl lg:px-6 "
              href="/"
            >
              Go to Home
            </Link>
            <Link
              className="border-[#F15C5A] border rounded-md p-2   text-white hover:text-blue-300 mb-2 text-xl lg:text-3xl lg:px-6 "
              href="/contact"
            >
              Contact Us
            </Link>
          </nav>
        </div>
      </div>
    </Div>
  );
};

export default Custom404;
